<template>
  <div>
    <Modal
      v-model="showModal"
      title="Créer une facture"
      modalClass="fullWidthModal"
      @close="modalClosed"
    >
      <form>
        <b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le système prend en considération que les opérations validés. <br>
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le contrôle des attachements se fait en utilisant le Montant <b>H.T</b>. <br>
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le contrat utilisé: <b>{{ contractRef }}</b>.
        </b-alert>

        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">N° de facture *</label>
              <input
                id="projectname"
                type="text"
                v-model="newInvoiceForm.invoiceNo"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.invoiceNo.$error,
                }"               
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.invoiceNo.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.invoiceNo.required">Champ obligatoire.</span>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">Date Facture *</label>
              <input
                id="projectname"
                type="date"
                class="form-control"
                v-model="newInvoiceForm.invDate"
                :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.invDate.$error,
                }"               
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.invDate.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.invDate.required">Champ obligatoire.</span>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">Date de réception *</label>
              <input
                id="projectname"
                type="date"
                class="form-control"
                v-model="newInvoiceForm.receptionDate"
                :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.receptionDate.$error,
                }"               
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.receptionDate.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.receptionDate.required">Champ obligatoire.</span>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">Total HT *</label>
              <input
                id="projectname"
                type="text"
                class="form-control"
                v-model="newInvoiceForm.untaxedAmnt"
                @input="_refreshDisplayedAmnt"
                :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.untaxedAmnt.$error,
                }"               
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.untaxedAmnt.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.untaxedAmnt.required">Champ obligatoire.</span>
                <span v-if="!$v.newInvoiceForm.untaxedAmnt.decimal">Champ invalide.</span>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">TAX *</label>
              <input
                id="projectname"
                type="text"
                class="form-control"
                v-model="newInvoiceForm.taxAmnt"
               :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.taxAmnt.$error,
                }"
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.taxAmnt.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.taxAmnt.required">Champ obligatoire.</span>
                <span v-if="!$v.newInvoiceForm.taxAmnt.decimal">Champ invalide.</span>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="formrow-email-input">TTC *</label>
              <input
                id="projectname"
                type="text"
                class="form-control"
                v-model="newInvoiceForm.totalAmnt"
                
                :class="{
                  'is-invalid': submitted && $v.newInvoiceForm.totalAmnt.$error,
                }"
                placeholder=""
              />
              <div v-if="submitted && $v.newInvoiceForm.totalAmnt.$error" class="invalid-feedback">
                <span v-if="!$v.newInvoiceForm.totalAmnt.required">Champ obligatoire.</span>
                <span v-if="!$v.newInvoiceForm.totalAmnt.decimal">Champ invalide.</span>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="">
              <div class="">
                <h4 class="card-title">
                  Liste des attachements
                  <div class="float-right">
                    <span>TTC Facture: {{ amounts.invoiceAmnt }}</span>
                    <span class="ml-3">Total Attachements: {{ amounts.selectedAmt }}</span>
                    <span class="ml-3" :class="`text-${amounts.diffClass}`">Différence: {{ amounts.diffAmnt }}</span>
                  </div>
                  </h4>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>N° Attachement</th>
                        <th>Date</th>
                        <th>Montant total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(attachment, index) in attachments" v-bind:key="attachment.id">
                        <th scope="row">
                          <b-form-checkbox
                            :value="index"
                            unchecked-value="not_accepted"
                            @input="handleSingleAttachmentClick(attachment)"
                            class="custom-checkbox-primary mb-3"
                            checked
                          ></b-form-checkbox>
                        </th>
                        <td>
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'invoicing.display',
                              params: { uid: attachment.uuid },
                            }"
                          >
                            {{ attachment.ref }}
                          </router-link>
                          </td>
                        <td>{{ attachment.dates.generatedAt }}</td>
                        <td><b>{{ attachment.total_amnt.withCurrency }}</b></td>
                        <td> <span v-html="attachment.status.rendered"></span> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" @click.prevent="generateInvoice" type="submit">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";

export default {
  props: {
    showModal: {},
    contract: null,
  },
  validations: {
    newInvoiceForm: {
      invoiceNo:  { required },
      invDate: { required },
      receptionDate: { required },
      untaxedAmnt: { required, decimal },
      taxAmnt: { required, decimal },
      totalAmnt: { required, decimal },
    },
  },
  data() {
    return {
      date: "",
      submitted: false,
      contractId: "",
      contracts: [],
      supplierName: "",
      contractRef: "",
      attachments: [],
      newInvoiceForm: {
        selectedAttachments: [] ,
        invoiceNo: "",
        invDate: "",
        receptionDate: "",
        untaxedAmnt: 0,
        taxAmnt: 0,
        totalAmnt: 0,
      },
      amounts: {
        invoiceAmnt : "",
        selectedAmt: "",
        diffAmnt: "",
        diffClass: "success"
      },
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
    };
  },

  mounted() {
    this.loadAvailableAttachments();
  },

  computed: {
    untaxedAmnt(){
      return this.newInvoiceForm.untaxedAmnt;
    },

    taxAmnt(){
      return this.newInvoiceForm.taxAmnt;
    }
  },

  watch: {
    showModal() {
      if (this.showModal) {
        // this.fetchContractsList();
      }
    },

    contract(){
      if(this.contract){
        this.contractRef = this.contract.ref;
        this.supplierName = this.contract.supplier.prestname;
      }
    },
    untaxedAmnt(){
      this._compute_ttc();
    },
    taxAmnt(){
      this._compute_ttc();
    },
  },

  methods: {
    _compute_ttc(){
      this.newInvoiceForm.totalAmnt = parseFloat(this.newInvoiceForm.untaxedAmnt) + parseFloat(this.newInvoiceForm.taxAmnt);
    },

    _format(amnt){
      return  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(amnt) ;
    },

    _computeSelectedAmntFromAttachments(){
      var sum = 0;
      this.newInvoiceForm.selectedAttachments.forEach(element => {
        sum += parseFloat(element.amnt);
      });
      return sum;
    },

    _refreshDisplayedAmnt(){
      var invAmnt = this.newInvoiceForm.untaxedAmnt;
      var selectedAmnt = this._computeSelectedAmntFromAttachments();
      var diff = invAmnt - selectedAmnt;
      if(diff != 0){
        this.amounts.diffClass = 'danger'
      }else{
        this.amounts.diffClass = 'success'
      }
      this.amounts.invoiceAmnt = this._format(invAmnt);
      this.amounts.selectedAmt = this._format(selectedAmnt);
      this.amounts.diffAmnt    = this._format(diff);
    },

    handleSingleAttachmentClick(attachment){
      var attachmentId = attachment.id;
      var attachmentAmnt = parseFloat(attachment.total_amnt.plain);
      var i = 0;
      // remove if already exists
      if(this.newInvoiceForm.selectedAttachments.filter(e => e.id == attachmentId).length > 0){
        this.newInvoiceForm.selectedAttachments.forEach(element => {
          if(element.id == attachmentId){
            this.newInvoiceForm.selectedAttachments.splice(i, 1);
            i++;
          }
        });
      }else{
        var attach = {
          id: attachmentId,
          amnt: attachmentAmnt,
        };
        this.newInvoiceForm.selectedAttachments.push(attach);
      }
      this._refreshDisplayedAmnt();
    },
    loadAvailableAttachments(){
      // attachments that are approved, and not been invoiced
      var loader = this.$loading.show();
        this.$http
          .post("/supplier_invoicing/getAvailableAttachments", {
            contractId: this.contract.uuid,
          })
          .then((res) => {
            loader.hide();
            var msg = res.data.msg;
            var status = res.data.status;
            var invoices = res.data.invoices;
            switch (status) {
                case 200:
                    this.attachments = invoices;
                break;
            
                case 500:
                    this.$toast.warning(msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.warning(error.message);
            // error.response.status Check status code
          })
          .finally(() => {
            loader.hide();
            //Perform action in always
          });
    },
    _checkDates() {
      return this.date.start != null && this.date.end != null;
    },
    
    _selectedAttachmentsAmountsAreValid(){
      var invAmnt = this.newInvoiceForm.totalAmnt;
      var selectedAmnt = this._computeSelectedAmntFromAttachments();
      var diff = invAmnt - selectedAmnt;
      return diff == 0
    },

    _selectedAttachmentsAreCompliant(){
      var valid = true;
      // check if we have at least one selected attachment
      if(this.newInvoiceForm.selectedAttachments.length == 0){
        valid = false;
        this.$toast.error("Vous devez selectionner au moin un attachement pour pouvoir continuer");
      }
      // recheck amounts again
      if(!this._selectedAttachmentsAmountsAreValid()){
        valid = false;
        this.$toast.error("La somme des attachement n'est pas conforme avec la somme de la facture");
      }
      return valid;
    },

    generateInvoice() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.newInvoiceForm.$invalid && this._selectedAttachmentsAreCompliant()){
        var loader = this.$loading.show();
          this.$http
            .post("/supplier_invoicing/generateInvoice", {
              contractId: this.contract.uuid,
              data: this.newInvoiceForm,
            })
            .then((res) => {
              loader.hide();
              var msg = res.data.original.msg;
              var status = res.data.original.status;
              var invoice = res.data.original.invoice;
              switch (status) {
                  case 200:
                      var router = this.$router;
                      this.$toast.success(msg);
                      this.showModal = false;
                      // redirect to invoice display page
                      router.push({ name: "suppliers_invoices.display", params: {uid:invoice.uuid } });
                  break;
              
                  case 500:
                      this.$toast.warning(msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.warning(error.message);
              // error.response.status Check status code
            })
            .finally(() => {
              loader.hide();
              //Perform action in always
            });
      }
    },
    modalClosed() {
      this.$emit("closeNewInvoiceModal", true);
    },
  },
};
</script>
